/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import './src/sass/app.scss'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';

// All Js File Import
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js';
import "jquery.ripples"

